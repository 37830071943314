import React from 'react'
import {ChatBotOffCanvas} from './pages/offcanvasPage'
import {ChatBotButton} from './pages/offcanvasButton'
import {ChatBotHome} from './pages/Home'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import 'bootstrap/dist/js/bootstrap.bundle';

export const ChatBot = () => {
  
  // useEffect(()=>{
  //  document.getElementById('floatingbutton').click();
  // }, [])
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Router>
      <Routes>
      <Route exact path='/offcanvasPage' element={<ChatBotOffCanvas />}></Route>
      <Route exact path='/offcanvasButton' element={<ChatBotButton />}></Route>
       <Route exact path='/' element={<ChatBotHome />}></Route> 
         </Routes>
      </Router>
     
    </div>
  )
}
