import React from 'react'
import { BanditBotOffCanvasTest } from './BanditBotOffcanvasTesting'
export const BanditBotTest = ({handleClose}) => {
  return (
    <>
      <BanditBotOffCanvasTest
       handleClose={handleClose}
      />
    </>
  )
}
