import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import "./styles.css";
import reportWebVitals from './reportWebVitals';
import { ChatBot } from './ChatBot';
import ChatBotOffCanvas from './pages/offcanvasPage'
import '../src/style/@mdi/font/css/materialdesignicons.min.css'
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChatBot/>
);

reportWebVitals();
